import React, { useState } from "react";
import "./ContactForm.css";
import arrow from "../../assest/Contact-us/Arrowimg.svg";
import emailjs from "emailjs-com";

const ContactForm = () => {
  const values = {
    name: "",
    phoneNumber: "",
    servicetype: "",
    mail: "",
    message: "",
  };

  const [formdata, setformdata] = useState(values);
  const [formErrors, setFormErrors] = useState({});
  const [showOptions, setShowOptions] = useState(false);

  const formDetails = [
    {
      id: 1,
      name: "name",
      label: "Name",
    },
    {
      id: 2,
      name: "mail",
      label: "Enter Your Mail",
    },
    {
      id: 3,
      name: "servicetype",
      label: "Service Type",
      options: [
        "Car Clean",
        "Add On Service",
        "Doorstep Car Machine Polish",
        "Doorstep Car Insurance",
      ],
    },
    {
      id: 4,
      name: "phoneNumber",
      label: "Mobile No",
    },
    {
      id: 5,
      name: "message",
      label: "Message Here",
    },
  ];

  const handleChange = (e) => {
    setformdata({ ...formdata, [e.target.name]: e.target.value });
  };

  const handleArrowClick = () => {
    setShowOptions((prevShowOptions) => !prevShowOptions);
  };

  const handleOptionClick = (option) => {
    setformdata({ ...formdata, servicetype: option });
    setShowOptions(false);
  };

  const validate = (values) => {
    const errors = {};
    // Regular expression for email validation
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!values.name) {
      errors.name = "Name is required!";
    }

    if (!values.mail) {
      errors.mail = "Email is required!";
    } else if (!regex.test(values.mail)) {
      errors.mail = "Please enter a valid email address!";
    }

    if (!values.phoneNumber) {
      errors.phoneNumber = "Mobile number is required!";
    }

    if (!values.message) {
      errors.message = "Message is required!";
    }

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validate(formdata);
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      emailjs
        .send(
          "service_2jjnq15",
          "template_x8ah22t",
          formdata,
          "tE_o6vBiidb8yU2Wj"
        )
        .then((response) => {
          alert(
            "The Form submitted successfully. We will review your information and contact you to confirm the details of your appointment. Thank you for contacting Vgraft Hair Transplant."
          );
          console.log("Email sent successfully!", response);
        })
        .catch((error) => {
          alert("Error sending email: " + error);
          console.error("Error sending email:", error);
        });
    } else {
      alert("Kindly enter the required fields");
      console.log("Form has errors. Submit prevented.");
    }
  };

  return (
    <div className="formfull">
      <h5>Fill Your Details and We Will Reach You Soon</h5>
      <form onSubmit={handleSubmit}>
        {formDetails?.map((form) => (
          <div key={form?.id}>
            <>
              {form?.name === "servicetype" && (
                <div className="soln-form"></div>
              )}
              <div className="formic">
                <label className={formdata[form.name] ? "hidden" : ""}>
                  {form?.label}
                </label>
                {form?.name === "servicetype" ? (
                  <div className="custom-select">
                    <input
                      type="text"
                      name={form?.name}
                      placeholder={form?.placeholder}
                      value={showOptions ? "" : formdata.servicetype}
                      readOnly
                      className="in"
                    />
                    <div
                      className={`arrows ${showOptions ? "rotated" : ""}`}
                      onClick={handleArrowClick}
                    >
                      <img src={arrow} alt="arrow" />
                    </div>
                  </div>
                ) : (
                  <input
                    type="text"
                    name={form?.name}
                    placeholder={form?.placeholder}
                    onChange={handleChange}
                  />
                )}
              </div>

              <div>
                {formErrors[form?.name] && (
                  <p className="error">{formErrors[form?.name]}</p>
                )}
              </div>

              {form?.name === "servicetype" && (
                <div className={`options ${showOptions ? "show-options" : ""}`}>
                  {form?.options?.map((option) => (
                    <div
                      key={option}
                      className="option"
                      onClick={() => handleOptionClick(option)}
                    >
                      <h5 className="optionsegment">{option}</h5>
                    </div>
                  ))}
                </div>
              )}
            </>
          </div>
        ))}

        <button className="btn9">Submit</button>
      </form>
    </div>
  );
};

export default ContactForm;
