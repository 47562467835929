import React from "react";
import "./RevenueDetails.css";

const RevenueDetails = () => {
  const revenueList = [
    {
      id: 1,
      num: "855+",
      texts: "Subscribers",
    },
    {
      id: 2,
      num: "350+",
      texts: "Experts",
    },
    {
      id: 3,
      num: "5000+",
      texts: "Car Cleaning",
    },
    {
      id: 4,
      num: "3500+",
      texts: "Happy Clients",
    },
  ];

  return (
    <div className="revenuemain">
      <div className="subrevenue">
        {revenueList?.map((data2) => (
          <div className="subtext" key={data2?.id}>
            <div className="vertical-line"></div>
            <div>
              <h1>{data2?.num}</h1>
              <p>{data2?.texts}</p>
              <div className="horizontal-line"></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RevenueDetails;
