import React, { useState } from "react";
import "./ServiceDaily.css";
import LandingButton from "../../component/button/LandingButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import DoorStepImage from "../../assest/Services/Doorstepservice.svg";
import Modal from "../../component/modal/Modal";
import Contact from "../contact_Us/Contact";

const ServiceDoorStep = () => {
  const [modalshow, setModalShow] = useState(false);
  const rfidhandle = () => {
    setModalShow(true);
  };
  const servicedailylist = [
    {
      id: 1,
      describe3:
        " we understand that your vehicle deserves the best care, and that includes maintaining its exterior brilliance. Introducing our Doorstep Car Machine Polish service – a convenient and effective way to bring back the showroom shine without leaving the comfort of your home.",
    },
    {
      id: 2,
      title3: "Professional Shine, Right at Your Door :",
      describe3:
        "Experience the convenience of professional-grade machine polishing without the need to visit a physical location. Our skilled technicians bring advanced equipment to your doorstep, ensuring your vehicle receives the attention it deserves.",
    },
    {
      id: 3,
      title3: "Tailored to Your Schedule :",
      describe3:
        " Life is busy, and we get that. With our Doorstep Car Machine Polish service, you can choose a time that suits your schedule, and our team will arrive fully equipped to perform the polishing right at your location.",
    },
    {
      id: 3,
      title3: "Preserve and Enhance Resale Value :",
      describe3:
        "Regular machine polishing not only enhances your vehicle's aesthetics but also contributes to maintaining its resale value. Our services are an investment in the long-term beauty and marketability of your vehicle",
    },
  ];

  return (
    <div className="dailymain1">
      <div className="servicetext3">
        <FontAwesomeIcon icon={faCircle} className="iconcircleBlue" />
        <h5>Our Services</h5>
      </div>
      <div className="serviceDaily1">
        <div className="dailytext1">
          <h3>
            Doorstep Car <span>Machine Polish </span>
          </h3>
          {servicedailylist.map((data4, index) => (
            <div key={index.id}>
              <h4>{data4.title3}</h4>
              <h5>{data4.describe3}</h5>
            </div>
          ))}
        </div>
        <div className="serviceimgsection">
          <img src={DoorStepImage} alt="Dailyimage" />
        </div>
        <div className="servicebtn">
          <LandingButton onClick={rfidhandle} btnitem>
            To Know More
          </LandingButton>
        </div>
      </div>
      {modalshow && (
        <Modal modalshow={modalshow} setModalShow={setModalShow}>
          <Contact />
        </Modal>
      )}
    </div>
  );
};

export default ServiceDoorStep;
