import React from "react";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <div className="privacymain">
      <h1>Privacy Policy </h1>
      <p>
        Welcome to FRAGRANT CARS AND SOLUTIONS PVT LTD., This Privacy Policy
        explains how we collect, use, disclose, and safeguard your information
        when you use our services. Please read this policy carefully to
        understand our practices regarding your personal data and how we handle
        it.
      </p>
      <div className="vl"></div>
      <div className="privacysub">
        <div className="informationsection">
          <h3>1. Information We Collect</h3>
          <p> Personal Data:</p>
          <p>
            a) Name, address, email address, phone number, and payment
            information
          </p>
          <li className="privacylist1">Usage Data:</li>
          <p>
            b) Information about your interactions with our services, such as
            booking details, cleaning history, and customer service
            interactions.
          </p>
          <li className="privacylist1">Device Data:</li>
          <p>
            c) Information from your device, such as IP address, browser type,
            operating system, and device identifiers.
          </p>
          <li className="privacylist1">Location Data:</li>
          <p>d) Real-time location information when you use our services</p>
        </div>
        <div className="useinformation">
          <h3>2. How We Use Your Information</h3>
          <p>
            We use the information we collect for various purposes, including
            to:
          </p>
          <ul>
            <li className="privacylist1">
              Provide and manage our car services.
            </li>
            <li className="privacylist1">
              Process your transactions and send related information, including
              booking confirmations and receipts.
            </li>
            <li className="privacylist1">
              Communicate with you about your bookings, account information, and
              customer service inquiries.
            </li>
            <li className="privacylist1">
              Personalize your experience and tailor our services to your
              preferences.
            </li>
            <li className="privacylist1">
              Improve our services through research and analysis of usage trends
            </li>
          </ul>
        </div>
      </div>
      <hr style={{ width: "90%" }} />
      <div className="privacysub">
        <div className="informationsection">
          <h3>3. How We Share Your Information</h3>
          <p>We may share your information with:</p>
          <ul>
            <li className="privacylist1">Service Providers</li>
            <li className="privacylist1">
              Third-party vendors that assist us in providing our services.
            </li>
            <li className="privacylist1">Business Transfers</li>
            <li className="privacylist1">
              In connection with, or during negotiations of, any merger, sale of
              company assets, financing, or acquisition of all or a portion of
              our business by another company.
            </li>
            <li className="privacylist1">Legal Requirements: </li>
            <li className="privacylist1">
              When required by law or to protect our rights, property, or
              safety, and those of our customers or others.
            </li>
          </ul>
        </div>
        <div className="useinformation">
          <h3>5. Your Privacy Rights</h3>
          <p>
            Depending on your location, you may have the following rights
            regarding your personal data:
          </p>
          <ul>
            <li className="privacylist1">
              Access: Request access to the personal data we hold about you
            </li>
            <li className="privacylist1">
              Correction: Request correction of incorrect or incomplete data.
            </li>
            <li className="privacylist1">
              Deletion: Request deletion of your personal data.
            </li>
            <li className="privacylist1">
              Objection: Object to the processing of your personal data.
            </li>
            <li className="privacylist1">
              Portability: Request the transfer of your personal data to another
              party.
            </li>
          </ul>
        </div>
      </div>
      <hr style={{ width: "90%" }} />
      <div className="privacysub">
        <div className="informationsection">
          <h3>4. Data Security</h3>
          <p>
            We use administrative, technical, and physical security measures to
            protect your personal data. However, no method of transmission over
            the internet or method of electronic storage is 100% secure
          </p>
        </div>
        <div className="useinformation">
          <h3>6. Third-Party Links</h3>
          <p>
            Our services may contain links to third-party websites. We are not
            responsible for the privacy practices or the content of those
            websites.
          </p>
        </div>
      </div>
      <hr style={{ width: "90%" }} />
      <div className="privacysub">
        <div className="informationsection">
          <h3>7. Changes to This Privacy Policy</h3>
          <p>
            We may update this Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page
            with a new effective date.
          </p>
        </div>
        <div className="useinformation">
          <h3>9. How we safeguard your information</h3>
          <p>
            We maintain reasonable technical, administrative, and physical
            security and confidentiality measures designed to help protect your
            information from unauthorized access or use. We also require (other
            than in certain emergency situations) third party service providers
            acting on our behalf or with whom we share your information to
            provide similar security and confidentiality measures.
          </p>
        </div>
      </div>
      <hr style={{ width: "90%" }} />
      <div className="privacysub">
        <div className="informationsection">
          <h3>8. Cookies / Tracking Technologies</h3>
          <p>
            FRAGRANT CARS may use cookies, pixel tags, web beacons and other
            tracking technologies on our websites, applications, email messages
            and advertisements, sometimes across devices, to gather information
            about your visit (such as demographic data, browser type, IP
            address, pages visited, activities conducted on the page, and the
            day and time of your visit to app). Using cookies provides benefits
            to you, such as allowing you to maintain your account login
            information or contact information. In addition, we use information
            gathered from cookies to autofill fields on forms such as the city,
            state or zip code associated with your IP address. This information
            can be corrected by you before submitting any form or inquiry or you
            can disable the cookie as discussed below and the information will
            not be collected.
          </p>
        </div>
        <div className="useinformation">
          <h3>10. Contact Us</h3>
          <p>
            If you have any questions or concerns about this Privacy Policy,
            please contact us at
          </p>
          <p>Email: fragrantcars@gmail.com</p>
          <p>Phone: 98407 92337</p>
          <p>
            Address: FRAGRANT CARS AND SOLUTIONS PVT LTD, Puzhuthivakkam,
            Chennai60009
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
