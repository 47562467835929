import React from "react";
import "./PrivacyPolicy.css";

const RefundPolicy = () => {
  return (
    <div className="privacymain">
      <h1>Service Refund Policy </h1>
      <div>
        <h3>Refund Policy </h3>
        <p>
          Thank you for choosing FRAGRANT CARS AND SOLUTIONS PVT LTD., We strive
          to provide the best possible service to our clients. If you are not
          completely satisfied with your purchase, we are here to help.
        </p>
      </div>
      <div>
        <h3>Eligibility Criteria</h3>
        <li className="privacylist1">
          Define the conditions under which a refund is applicable, such as
          dissatisfaction with the service, non-delivery of service, or service
          not as described.
        </li>
      </div>
      <div>
        <h3>Eligibility for Refunds</h3>
        <ul>
          <li className="privacylist1">
            Refunds are available for services that do not meet the client's
            expectations due to non-delivery or significant deviation from the
            agreed-upon service.
          </li>
          <li className="privacylist1">
            Refund requests must be made within 7-15 days of the service
            delivery date if approved by management.
          </li>
        </ul>
      </div>
      <div>
        <h3>Requesting a Refund</h3>
        <ul>
          <li className="privacylist1">
            To request a refund, please contact us at fragrantcars@gmail.com /
            9840792337. Upon mutual decision amongst management members and
            genuine case- % of refund or full refund will be intimated through
            mail and message
          </li>
          <li className="privacylist1">
            Provide your service details, including the date of purchase and a
            brief explanation of the reason for the refund request.
          </li>
          <li>
            Any relevant documentation or evidence to support your request
            should be included.
          </li>
        </ul>
      </div>
      <div>
        <h3>Additional Conditions :</h3>
        <ul>
          <li className="privacylist1">
            Full refunds are provided if the service was not delivered or was
            significantly different from what was described.
          </li>
          <li className="privacylist1">
            Partial refunds may be granted in cases where the service was
            partially delivered and met some but not all expectations.
          </li>
        </ul>
      </div>
      <div>
        <h3>Non-Refundable Services :</h3>
        <ul>
          <li className="privacylist1">
            Customized services tailored specifically to the client’s
            requirements are non-refundable.
          </li>
          <li className="privacylist1">
            One-time consultations or sessions that have been attended are
            non-refundable
          </li>
        </ul>
      </div>
      <div>
        <h3>Processing Time :</h3>
        <ul>
          <li className="privacylist1">
            Refunds will be processed within 15 business days from the approval
            of your request.
          </li>
          <li className="privacylist1">
            The refund will be issued to the original method of payment.
          </li>
        </ul>
      </div>
      <div>
        <h3>Additional Conditions :</h3>
        <li className="privacylist1">
          A cancellation fee of 15% may apply if the service was initiated but
          not completed due to client cancellation.
        </li>
      </div>
      <h3>
        For any questions or concerns regarding our refund policy, please
        contact us at fragrantcars@gmail.com / +91-98407 92337.
      </h3>
    </div>
  );
};

export default RefundPolicy;
