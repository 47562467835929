import React from "react";
import LandingPage from "../../component/landingPage/LandingPage";
import ServiceMain from "./ServiceMain";
import SubscriptionService from "../../component/Subscription/SubscriptionService";

const Services = () => {
  const backgroundImage = "landingService";
  const backgroundImage1 = "landingServicePage";

  const data = {
    title: "Clean Car is our Priority",
    description:
      " We have a range of cleaning services suiting your needs, we have designed it such a way that your normal cleaning agent cost and our professional cost should match ! Doesa this give you a relieved feeling-If Yes, download our app & enroll for your favourite service.",
    backgroundImage: backgroundImage,
    backgroundImage1: backgroundImage1,
  };

  return (
    <>
      <div>
        {/* {pageLanding?.map((data) => ( */}
        <LandingPage key={"services"} {...data} />
        {/* ))} */}
      </div>
      <div>
        <ServiceMain />
        <SubscriptionService />
      </div>
    </>
  );
};
export default Services;
