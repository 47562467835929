import React, { useState } from "react";
import "./ServiceDaily.css";
import Dailyimage from "../../assest/Services/dailyservice.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import LandingButton from "../../component/button/LandingButton";
import Contact from "../contact_Us/Contact";
import Modal from "../../component/modal/Modal";

const ServiceDaily = () => {
  const [modalshow, setModalShow] = useState(false);
  const rfidhandle = () => {
    setModalShow(true);
  };
  const servicedailylist = [
    {
      id: 1,
      title3: "Convenient and Time-Efficient :",
      describe3:
        "Our daily car Clean services are tailored to fit seamlessly into your busy schedule. With quick and efficient processes, we ensure that your vehicle receives the care it needs without causing any disruption to your daily routine",
    },
    {
      id: 2,
      title3: "Professional Attention to Detail :",
      describe3:
        "Our skilled team takes pride in providing a thorough and detailed cleaning for your vehicle. From exterior surfaces to interior detailing, we leave no stone unturned, ensuring that every inch of your vehicle is spotless.",
    },
    {
      id: 3,
      title3: "Tailored Packages for Every Need :",
      describe3:
        "Whether you're looking for a basic exterior Clean or a comprehensive interior and exterior detailing, we offer a range of packages to suit your preferences. Our goal is to provide flexibility, catering to the unique needs of each vehicle owner.",
    },
    {
      id: 3,
      title3: "Protect Your Investment :",
      describe3:
        "Regular Cleaning is more than just about aesthetics; it's a crucial aspect of vehicle maintenance. Our daily car Clean services help protect your investment by removing dirt, grime, and contaminants that can contribute to paint damage and corrosion.",
    },
  ];

  return (
    <div className="dailymain1">
      <div className="servicetext3">
        <FontAwesomeIcon icon={faCircle} className="iconcircleBlue" />
        <h5>Our Services</h5>
      </div>
      <div className="serviceDaily1">
        <div className="dailytext1">
          <h3>Daily Car Cleaning</h3>
          {servicedailylist.map((data4, index) => (
            <div key={index.id}>
              <h4>{data4.title3}</h4>
              <h5>{data4.describe3}</h5>
            </div>
          ))}
        </div>
        <div className="serviceimgsection">
          <img src={Dailyimage} alt="Dailyimage" />
        </div>
        <div className="servicebtn">
          <LandingButton onClick={rfidhandle} btnitem>
            To Know More
          </LandingButton>
        </div>
      </div>

      {modalshow && (
        <Modal modalshow={modalshow} setModalShow={setModalShow}>
          <Contact />
        </Modal>
      )}
    </div>
  );
};

export default ServiceDaily;
