import Approuter from "./component/appRouter/Approuter";

function App() {
  return (
    <>
      <Approuter />
    </>
  );
}

export default App;
