import React from "react";
import "./Contact.css";
import ContactInformation from "./ContactInformation";
import ContactForm from "./ContactForm";

const Contact = () => {
  return (
    <div className="contactmain">
      {/* <img className="img9" src={imgcontact} alt="imgcontact" /> */}

      <div className="formcontact1">
        <h1>Get in Touch</h1>
        <p>
          For your convenience, you can also use the contact form below to send
          us a message. Please provide as much detail as possible so that we can
          better assist you.
        </p>
        <div className="contactform">
          <ContactInformation />
          <ContactForm />
        </div>
      </div>
    </div>
  );
};

export default Contact;
