import React from "react";
import "./BrandMarket.css";
import SaloonImg from "../../assest/Gallery/Saloon.svg";
import HatchbackImg from "../../assest/Gallery/Hatchback.svg";
import ConvertibleImg from "../../assest/Gallery/Convertible.svg";
import MPVImg from "../../assest/Gallery/MPV.svg";
import SUVImg from "../../assest/Gallery/SUV.svg";
import CoupeImg from "../../assest/Gallery/Coupe.svg";
import EstateImg from "../../assest/Gallery/Estate.svg";

const BrandMarket = () => {
  const brandList = [
    {
      id: 1,
      pic: SaloonImg,
      brandName: "Saloon",
    },
    {
      id: 2,
      pic: HatchbackImg,
      brandName: "Hatchback",
    },
    {
      id: 3,
      pic: ConvertibleImg,
      brandName: "Convertible",
    },
    {
      id: 4,
      pic: MPVImg,
      brandName: "MPV",
    },
    {
      id: 5,
      pic: SUVImg,
      brandName: "SUV",
    },
    {
      id: 6,
      pic: CoupeImg,
      brandName: "Coupe",
    },
    {
      id: 7,
      pic: EstateImg,
      brandName: "Estate",
    },
    {
      id: 8,
      pic: SaloonImg,
      brandName: "Saloon",
    },
    {
      id: 9,
      pic: HatchbackImg,
      brandName: "Hatchback",
    },
    {
      id: 10,
      pic: ConvertibleImg,
      brandName: "Convertible",
    },
    {
      id: 11,
      pic: MPVImg,
      brandName: "MPV",
    },
    {
      id: 12,
      pic: SUVImg,
      brandName: "SUV",
    },
    {
      id: 13,
      pic: CoupeImg,
      brandName: "Coupe",
    },
    {
      id: 14,
      pic: EstateImg,
      brandName: "Estate",
    },
    {
      id: 15,
      pic: SaloonImg,
      brandName: "Saloon",
    },
    {
      id: 16,
      pic: HatchbackImg,
      brandName: "Hatchback",
    },
    {
      id: 17,
      pic: ConvertibleImg,
      brandName: "Convertible",
    },
    {
      id: 18,
      pic: MPVImg,
      brandName: "MPV",
    },
    {
      id: 19,
      pic: SUVImg,
      brandName: "SUV",
    },
    {
      id: 20,
      pic: CoupeImg,
      brandName: "Coupe",
    },
    {
      id: 21,
      pic: EstateImg,
      brandName: "Estate",
    },
  ];

  return (
    <div className="brandcompy">
      <div className="Marquee">
        <div className="Marquee-content">
          {brandList.map((certifiedData, index) => (
            <div key={index.id} className="Marquee-tag">
              <img className="marqueeimg" src={certifiedData?.pic} />
              <p>{certifiedData?.brandName}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BrandMarket;
