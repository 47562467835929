import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import Circleimg from "../../assest/Home/circleimg12.svg";
import Starimg from "../../assest/Home/starimg12.svg";
import "./Carosal.css";

const Carosal = () => {
  const splideOptions = {
    type: "loop",
    perPage: 1,
    autoplay: true,
    dots: true,
    pagination: true,
    speed: 900,
    interval: 2000,
    arrows: false,
    paginationSize: 4,
  };

  const cardsData = [
    {
      id: 1,
      title: "KAVIN",
      desination: "4/5 Ratings",
      image: Circleimg,
      Image1: Starimg,
      describe:
        "Thank-you so much. A great & neat job done by you all.It was doorstep and entire work was done at home. All they sought was a bucket and I left asking to call when done.Appreciate the detailed interior and exterior cleaning work. When I came back to check the finished work, was amazed! The car looks very good and just brand new. Found them to be honest and charged a reasonable price.",
    },
    {
      id: 2,
      title: "RAM",
      desination: "4/5 Ratings",
      image: Circleimg,
      Image1: Starimg,
      describe:
        "They have done great job. Good professionalism portrayed and you don’t get this kind of service even you visit any show room. They carry everything which is required for car cleaning etc and they did fantastic job Thank you Fragrant Cars",
    },
    {
      id: 3,
      title: "JOHN",
      desination: "4/5 Ratings",
      image: Circleimg,
      Image1: Starimg,
      describe:
        "I highly recommend this service! They are both affordable and provide excellent service. The team is very passionate and dedicated to their work. I had a great experience with them and would definitely recommend them to anyone looking for quality service.",
    },
    {
      id: 4,
      title: "RENU",
      desination: "4/5 Ratings",
      image: Circleimg,
      Image1: Starimg,
      describe:
        "Excellent work done by Fragrant Cars team. They were very professional from start to finish. I had taken exterior n interior detailing, they took good time did a very perfect job at a very reasonable price. My car looks new n clean now. 100% satisfied, Highly recommend their service. Thank you Fragrant Cars",
    },
  ];

  return (
    <div className="card">
      <Splide options={splideOptions}>
        {cardsData?.map((items) => (
          <SplideSlide key={items.id} className="spl">
            <div className="carousel">
              <div className="carosalsliding">
                <div className="carousels">
                  <img
                    className="circleimg4"
                    src={items.image}
                    alt={items.title}
                  />
                  <div>
                    <h3>{items.title}</h3>
                    <span>{items.desination}</span>
                    <img className="imgstar" src={items?.Image1} alt="Image1" />
                  </div>
                </div>

                <p>{items.describe}</p>
              </div>
            </div>
          </SplideSlide>
        ))}
      </Splide>
    </div>
  );
};

export default Carosal;
