import React from "react";
import LandingPage from "../../component/landingPage/LandingPage";
import OurGallery from "./OurGallery";
import VideoGallery from "./VideoGallery";
import BrandMarket from "../../component/Brand/BrandMarket";

const Gallery = () => {
  const backgroundImage = "landingGallery";
  const backgroundImage1 = "LandingGalleryPage";

  const pageLanding = [
    {
      title: "Our Gallery",
      description:
        " Our gallery captures the essence of excellence in every project, event, and collaboration. Each image tells a story of precision, creativity, and the unwavering commitment to delivering the best to our clients.",
      backgroundImage: backgroundImage,
      backgroundImage1: backgroundImage1,
    },
  ];
  return (
    <>
      <div>
        {pageLanding?.map((data) => (
          <LandingPage key={data?.id} {...data} landtextGallery />
        ))}
      </div>
      <div>
        <OurGallery />
        {/* <VideoGallery /> */}
        <BrandMarket />
      </div>
    </>
  );
};

export default Gallery;
