import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import Home from "../../pages/home/Home";
import Services from "../../pages/services/Services";
import Gallery from "../../pages/gallery/Gallery";
import AboutUsMain from "../../pages/About_Us/AboutUsMain";
import PrivacyPolicy from "../../pages/privacyPolicy/PrivacyPolicy";
import TermsCondition from "../../pages/privacyPolicy/TermsCondition";
import RefundPolicy from "../../pages/privacyPolicy/RefundPolicy";

const Approuter = () => {
  return (
    <div>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/About_Us" element={<AboutUsMain />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/termsCondition" element={<TermsCondition />} />
          <Route path="/refundPolicy" element={<RefundPolicy />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
};

export default Approuter;
