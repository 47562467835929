import React, { useState } from "react";
import "./DeleteAccountForm.css";

const DeleteAccountForm = () => {
  const formDetails = {
    name: "",
    mobile: "",
    email: "",
    reason: "",
  };
  const [formDatas, setFormDatas] = useState(formDetails);
  const [message, setMessage] = useState("");

  const formDataList = [
    {
      id: 1,
      label: "Name",
      name: "name",
      type: "text",
    },
    {
      id: 2,
      label: "Mobile Number",
      name: "mobile",
      type: "text",
    },
    {
      id: 3,
      label: "Email Id",
      name: "email",
      type: "email",
    },
    {
      id: 4,
      label: "Reason For why you deleting the account",
      name: "reason",
      type: "text",
    },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormDatas({ ...formDatas, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { name, mobile, email, reason } = formDatas;
    if (!name || !mobile || !email || !reason) {
      setMessage("Please fill in all the details.");
      return;
    }

    const formBody = new URLSearchParams(formDatas).toString();

    try {
      const response = await fetch(
        "Android_API/api-1.2.11/userDelete_request.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formBody,
        }
      );

      const data = await response.json();

      if (data.status === "error") {
        setMessage(data.message || "Unknown error");
      } else {
        setMessage(
          "Dear Customer, Our Fragrantcars Admin will shortly Contact you"
        );
      }
    } catch (error) {
      console.error("Error:", error);
      setMessage("Failed to send request: " + error.message);
    }
  };

  return (
    <div className="deleteformmain">
      <div className="deleteformsub">
        <div className="deletetext">
          <h1>Are you sure you want to delete the account?</h1>
          <p>Send The Request</p>
        </div>
        <div className="formicdata">
          {formDataList.map((formValue, index) => (
            <div key={index}>
              <label className="labeldata" htmlFor={formValue.name}>
                {formValue.label}
              </label>
              {formValue.name === "reason" ? (
                <textarea
                  type={formValue.type}
                  name={formValue.name}
                  value={formDatas[formValue.name] || ""}
                  onChange={handleChange}
                  className="textareadata"
                ></textarea>
              ) : (
                <input
                  type={formValue.type}
                  name={formValue.name}
                  value={formDatas[formValue.name] || ""}
                  onChange={handleChange}
                  className="inputdata"
                />
              )}
            </div>
          ))}

          {message && <p className="error-message">{message}</p>}

          <button className="btn9" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteAccountForm;
