import React, { useState } from "react";
import "./CircleAnimation.css";
import exteriorImg from "../../assest/Home/exterior.svg";
import interiorImg from "../../assest/Home/interior.svg";
import EliminatesImg from "../../assest/Home/Eliminates.svg";
import gracefullyImg from "../../assest/Home/gracefully.svg";

const CircleAnimation = () => {
  const [hoveredTextId, setHoveredTextId] = useState(null);
  const circlelist = [
    {
      id: 1,
      pics: exteriorImg,
      text: "Cleans your exterior thoroughly",
    },
    {
      id: 2,
      pics: interiorImg,
      text: "Clean your interior thoroughly",
    },
    {
      id: 3,
      pics: EliminatesImg,
      text: "Eliminates the odour from your car and give it a pleasant smell",
    },
    {
      id: 4,
      pics: gracefullyImg,
      text: "Help your car to age gracefully",
    },
  ];

  return (
    <>
      <div className="circletitle">
        <h1>How important is cleaning your car? </h1>
      </div>
      <div className="containermain">
        {circlelist.map((item) => (
          <div
            key={item.id}
            className="container"
            onMouseEnter={() => setHoveredTextId(item.id)}
            onMouseLeave={() => setHoveredTextId(null)}
          >
            <svg className="circle" viewBox="0 0 100 100">
              <defs>
                <linearGradient
                  id="gradient"
                  x1="0%"
                  y1="0%"
                  x2="100%"
                  y2="100%"
                >
                  <stop offset="0%" style={{ stopColor: "#0984FF" }} />
                  <stop offset="100%" style={{ stopColor: "#FFFFFF" }} />
                </linearGradient>
                <clipPath id={`circleClip${item.id}`}>
                  <circle cx="50" cy="50" r="40" />
                </clipPath>
              </defs>

              <g>
                <circle
                  cx="50"
                  cy="50"
                  r="40"
                  className="gradient"
                  style={{
                    transform:
                      hoveredTextId === item.id ? "rotate(-90deg)" : "",
                  }}
                />
                <image
                  href={item.pics}
                  clipPath={`url(#circleClip${item.id})`}
                  x="8"
                  y="8"
                  width="90"
                  height="90"
                />
              </g>
            </svg>
            <p
              className="circle-text"
              style={{
                color: hoveredTextId === item.id ? "#0984ff" : "#333",
              }}
            >
              {item.text}
            </p>
          </div>
        ))}
      </div>
    </>
  );
};

export default CircleAnimation;
