import React from "react";
import "./ContactInformation.css";
import phoneIcon from "../../assest/Contact-us/phoneicon.svg";
import mailIcon from "../../assest/Contact-us/mailicon.svg";
import locationIcon from "../../assest/Contact-us/locationicon.svg";

const ContactInformation = () => {
  return (
    <div className="contactinfo">
      <h2>Contact Information</h2>
      <div className="subcontact1">
        <img src={phoneIcon} alt={phoneIcon} />
        <h4>
          +91 7358705035 <br /> +91 9840792337
        </h4>
      </div>
      <div className="subcontact1">
        <img src={mailIcon} alt={mailIcon} />
        <h4>fragrantcars@gmail.com</h4>
      </div>
      <div className="subcontact1">
        <img src={locationIcon} alt={locationIcon} />
        <h4>Chennai</h4>
      </div>
      <div className="location">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.964271431842!2d80.20576737507648!3d12.974137037341379!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525d4735db102f%3A0x96fbbf2a97a39cbf!2sKGR%20MOTORS%20A%20premium%20partner%20of%20GO%20Bumpr!5e0!3m2!1sen!2sin!4v1712106251474!5m2!1sen!2sin"
          loading="lazy"
          className="mapstyle"
        ></iframe>
        <h3>Our Locations</h3>
      </div>
    </div>
  );
};

export default ContactInformation;
