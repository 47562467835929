import React from "react";
import "./ServiceProvider.css";
import ProfileImg1 from "../../assest/Home/profile1.svg";
import ProfileImg2 from "../../assest/Home/profile2.svg";
import { Link } from "react-router-dom";

const ServiceProvider = () => {
  const profileservice = [
    {
      id: 1,
      pics: ProfileImg2,
      name: "Subhashini Raakesh",
      designation: "Founder & Managing Director",
      linkedin: "https://www.linkedin.com/in/subhashini-raakesh-23231569",

      text4:
        "She has adequate industry experience of 21 years starting from selling credit cards to now founding her own start-up! It is definitely a story of Rags to Riches …though Riches is yet to be revealed ",
      text5:
        "With her skills and service delivery experience, we are sure to scale greater heights as success is always sweet but hard work to get ourselves here isn’t ! ",
    },
    {
      id: 1,
      pics: ProfileImg1,
      name: "G Rajesh Kumar",
      designation: "Executive Director ",
      linkedin: "https://www.linkedin.com/in/rajesh-kumar-979a1469",
      text4:
        "He comes from a completely technical know-how of CARS at its best! Having completed his Master's in ICE technology from the College of Engineering, Guindy - he has been a dominant strength in enabling build this company structure. His dedication to owning and running a successful Car Service Unit is unparalleled! ",
      text5:
        "His technical knowledge about cars, and the automobile industry as a whole comes from his own struggles and a humble background! Though he is technically qualified and has attended to more than 2000 cars in his service, his ideologies to stay grounded and customer-focused are exemplary! ",
    },
  ];

  return (
    <div className="servicemain">
      <div className="subservice">
        <div className="servicetext">
          <h1>Service Provider</h1>
          <p>
            Thus our organization was founded with an intention to automate the
            car cleaning service as an application! Both directors come from
            diversified backgrounds with native skills and 20+ years in the
            customer service industry. .
          </p>
        </div>
        <div className="serviceimg">
          {profileservice.map((datas, index) => (
            <div className="imgservicesec" key={datas?.id}>
              <img src={datas?.pics} alt={datas?.pics} />
              <div
                className={`servicetext12 ${
                  index % 2 === 0 ? "even-order" : "odd-order"
                }`}
              >
                <h1>{datas?.name}</h1>
                <p>{datas?.designation}</p>
                <h3>{datas?.text4}</h3>
                <h3>{datas?.text5}</h3>
                <a
                  href={datas?.linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  LinkedIn Profile : {datas?.linkedin}
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ServiceProvider;
