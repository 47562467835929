import React from "react";
import "./PrivacyPolicy.css";

const TermsCondition = () => {
  return (
    <div className="privacymain">
      <h1>Terms and Conditions</h1>
      <p>
        FRAGRANT CARS AND SOLUTIONS PVT LTD. – Application Usage-Terms and
        Conditions
      </p>
      <div className="vl"></div>
      <div className="privacysub">
        <div className="informationsection">
          <div>
            <h3>1. Introduction</h3>
            <p>
              Welcome to FRAGRANT CARS! These Terms and Conditions govern your
              use of our services . By accessing or using the Application, you
              agree to comply with and be bound by these Terms. If you do not
              agree with any part of these Terms, you must not use our
              Application.
            </p>
          </div>
          <hr style={{ width: "48.2vw", marginTop: "1.25vw" }} />
          <div>
            <h3>2. Acceptance of Terms</h3>
            <p>
              By using the Application, you affirm that you are at least 18
              years old or have reached the age of majority in your
              jurisdiction, and that you have read, understood, and accept these
              Terms.
            </p>
          </div>
          <hr style={{ width: "48.2vw", marginTop: "1.25vw" }} />
          <div>
            <h3>3. Changes to Terms</h3>
            <p>
              We reserve the right to modify these Terms at any time. Any
              changes will be effective immediately upon posting on the
              Application. Your continued use of the Application after such
              changes constitutes your acceptance of the new Terms.
            </p>
          </div>
        </div>
        <div className="useinformation">
          <h3>4. Use of the Application</h3>
          <li className="listprivacy">4.1 Account Registration</li>
          <p>
            To use certain features of the Application, you may need to register
            for an account. You agree to provide accurate, current, and complete
            information during the registration process and to update such
            information to keep it accurate, current, and complete.
          </p>
          <li className="listprivacy">4.2 User Responsibilities </li>
          <p>
            You are responsible for maintaining the confidentiality of your
            account login information and for all activities that occur under
            your account. You agree to notify us immediately of any unauthorized
            use of your account.
          </p>
          <li className="listprivacy">4.3 Prohibited Conduct</li>
          <p>
            You agree not to: - Use the Application for any illegal purpose or
            in violation of any local, state, national, or international law. -
            Engage in any activity that interferes with or disrupts the
            Application or the servers and networks connected to the
            Application. - Impersonate any person or entity, or falsely state or
            otherwise misrepresent your affiliation with a person or entity.
          </p>
        </div>
      </div>
      <hr style={{ width: "100%" }} />
      <div className="privacysub">
        <div className="informationsection">
          <div>
            <h3>5. Intellectual Property</h3>
            <li>5.1 Ownership</li>
            <p>
              All content, features, and functionality (including but not
              limited to text, graphics, logos, icons, images, audio clips,
              video clips, data compilations, and software) provided through the
              Application are owned by us or our licensors and are protected by
              intellectual property laws.
            </p>
            <li>5.2 License </li>
            <p>
              We grant you a limited, non-exclusive, non-transferable, and
              revocable license to access and use the Application for your
              personal, non-commercial use.
            </p>
          </div>
          <hr style={{ width: "48.2vw", marginTop: "1.25vw" }} />
        </div>
        <div className="useinformation">
          <h3>6. Privacy Policy</h3>
          <p>
            Our Privacy Policy explains how we collect, use, and protect your
            personal information. By using the Application, you consent to the
            collection and use of your information as outlined in our Privacy
            Policy.
          </p>
          <hr
            style={{
              width: "40.5vw",
              marginTop: "1.25vw",
              position: "relative",
              right: "4.3vw",
            }}
          />
        </div>
      </div>
      <div className="privacysub">
        <div className="informationsection">
          <h3>8. Indemnification</h3>
          <p>
            You agree to indemnify, defend, and hold harmless FRAGRANT CARS- its
            officers, directors, employees, and agents from and against any
            claims, liabilities, damages, losses, and expenses, including
            reasonable attorney's fees, arising out of or in any way connected
            with your use of the Application or violation of these Terms.
          </p>
        </div>
        <div className="useinformation">
          <h3>7. Disclaimers and Limitation of Liability</h3>
          <li className="listprivacy">7.1 Disclaimers</li>
          <p>
            The Application is provided on an "as is" and "as available" basis.
            We make no warranties, express or implied, regarding the operation
            or availability of the Application.
          </p>
          <li className="listprivacy">7.2 Limitation of Liability</li>
          <p>
            To the maximum extent permitted by law, we shall not be liable for
            any indirect, incidental, special, consequential, or punitive
            damages arising out of or related to your use of or inability to use
            the Application.
          </p>
        </div>
      </div>
      <hr style={{ width: "100%" }} />
      <div className="privacysub">
        <div className="informationsection">
          <div>
            <h3> 9. Termination</h3>
            <p>
              We may terminate or suspend your access to the Application at any
              time, without prior notice or liability, for any reason, including
              if you breach these Terms
            </p>
            <hr style={{ width: "48.2vw", marginTop: "1.25vw" }} />
          </div>
          <div>
            <h3> 10. Governing Law</h3>
            <p>
              These Terms shall be governed by and construed in accordance with
              the laws of state of Tamilnadu & country of India, without regard
              to its conflict of law principles.
            </p>
          </div>
        </div>
        <div className="useinformation">
          <h3>11. Contact Information</h3>
          <p>
            If you have any questions about these Terms, please contact us at
            fragrantcars@gmail.com By Management of FRAGRANT CARS AND SOLUTIONS
            PVT LTD
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsCondition;
