import React, { useState } from "react";
import "./ServiceAddOn.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import LandingButton from "../../component/button/LandingButton";
import AddOnImage from "../../assest/Services/AddOnservice.svg";
import Contact from "../contact_Us/Contact";
import Modal from "../../component/modal/Modal";

const ServiceAddOn = () => {
  const [modalshow, setModalShow] = useState(false);
  const rfidhandle = () => {
    setModalShow(true);
  };
  const servicedailylist = [
    {
      id: 1,
      describe3:
        "At Fragrant cars, we believe in going the extra mile to enhance your experience. Our range of add-on services is designed to elevate your satisfaction and meet the unique needs of your vehicle. Discover the premium enhancements we offer to make every visit extraordinary",
    },
    {
      id: 2,
      title3: "Comprehensive Options :",
      describe3:
        "Explore a diverse range of add-on services, including premium waxing, paint protection, interior fabric treatments, headlight restoration, and more. Our goal is to offer a comprehensive suite of options to address all aspects of your vehicle's care.",
    },
    {
      id: 3,
      title3: "Professional Expertise  :",
      describe3:
        "Our skilled team brings years of expertise to every add-on service. From trained technicians to specialized equipment, we ensure that each enhancement is executed with precision and care, providing you with unmatched quality.",
    },
    {
      id: 3,
      title3: "Preserving Resale Value :",
      describe3:
        "Investing in our add-on services not only enhances the current state of your vehicle but also contributes to its long-term value. Our treatments are designed to protect against wear and tear, ensuring that your vehicle maintains its appeal and resale value.",
    },
  ];

  return (
    <div className="dailymain1">
      <div className="servicetext4">
        <FontAwesomeIcon icon={faCircle} className="iconcircleBlue" />
        <h5>Our Services</h5>
      </div>
      <div className="AddOnMain1">
        <div className="addOnsection">
          <img src={AddOnImage} alt="AddOnImage" />
        </div>
        <div className="servicebtn1">
          <LandingButton onClick={rfidhandle} btnitem>
            To Know More
          </LandingButton>
        </div>
        <div className="addOntext1">
          <h3>Add on Service</h3>
          {servicedailylist.map((data4, index) => (
            <div key={index.id}>
              <h4>{data4.title3}</h4>
              <h5>{data4.describe3}</h5>
            </div>
          ))}
        </div>
      </div>
      {modalshow && (
        <Modal modalshow={modalshow} setModalShow={setModalShow}>
          <Contact />
        </Modal>
      )}
    </div>
  );
};

export default ServiceAddOn;
