import React from "react";
import "./Button.css";

const LandingButton = ({ btnitem, btn3Enabled, children, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={btnitem ? "btn2" : btn3Enabled ? "btn7" : "btn1"}
    >
      {children}
    </button>
  );
};

export default LandingButton;
