import React, { useState, useEffect } from "react";
import "./OurGallery.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

const OurGallery = () => {
  const [galleryImages, setGalleryImages] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  useEffect(() => {
    const fetchGalleryImages = async () => {
      setLoadingData(true);
      try {
        const response = await fetch("Android_API/api-1.2.11/get_gallery.php", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch gallery images");
        }

        const data = await response.json();
        // Sort the images by upload date
        const sortedImages = data.gallery.sort(
          (a, b) => new Date(b.upload_date) - new Date(a.upload_date)
        );
        // Set only the latest six images
        setGalleryImages(sortedImages.slice(0, 6));
        setLoadingData(false);
      } catch (error) {
        console.error("Error fetching gallery images:", error);
        setLoadingData(false);
      }
    };

    fetchGalleryImages();
  }, []);

  if (loadingData) return <div>Loading Data...</div>;

  return (
    <div className="gallerymain">
      <div className="subgallery">
        <div className="gallerycontent">
          <div className="gallerytext">
            <FontAwesomeIcon icon={faCircle} className="iconcircleBlue" />
            <h5>Our Gallery</h5>
          </div>
          <h1>Capturing Moments of Excellence</h1>
          <p>
            Embark on a visual journey showcasing the essence of Fragrant Cars
            and Solutions Pvt. Ltd., Our gallery is a curated collection of
            moments, experiences, and successes that reflect the heart and soul
            of our brand. Explore the videos below to discover the
            craftsmanship, dedication, and passion that define who we are.
          </p>
        </div>
        <div className="china">
          <div className="china1">
            {galleryImages.slice(0, 3).map((image) => (
              <div key={image.gallery_id} className="company-item">
                <img
                  className="companyimg"
                  src={image.simage}
                  alt={`Gallery ${image.gallery_id}`}
                />
              </div>
            ))}
          </div>
          <div className="china1">
            {galleryImages.slice(3).map((image) => (
              <div key={image.gallery_id} className="company-item">
                <img
                  className="companyimg"
                  src={image.simage}
                  alt={`Gallery ${image.gallery_id}`}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurGallery;
