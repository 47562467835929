import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

const TestimonialText = () => {
  return (
    <div className="testimonialtxt">
      <div className="testimonialtext1">
        <FontAwesomeIcon icon={faCircle} className="iconcircleBlack" />
        <h5>Testimonial</h5>
      </div>
      <h4>Client says About Fragrant</h4>
      <h6>
        We as a unit of Fragrant Cars and Solutions Pvt. Ltd., are excited to
        make this start-up idea a great stand-alone application in serving
        customer needs. Our objective is to run the business as a
        service-oriented organization. We hope to achieve many such milestones
        in the years to come with the blessings of our parents, family support,
        and customer satisfaction!
      </h6>
    </div>
  );
};

export default TestimonialText;
