import React, { useState } from "react";
import "./LandingPage.css";
import LandingButton from "../button/LandingButton";
import Modal from "../modal/Modal";
import Contact from "../../pages/contact_Us/Contact";

const LandingPage = ({
  backgroundImage,
  title,
  description,
  landtextGallery,
  backgroundImage1,
}) => {
  const [modalshow, setModalShow] = useState(false);
  const rfidhandle = () => {
    setModalShow(true);
  };
  return (
    <div className="landingPage">
      <img
        className="landinimg"
        src={require(`../../assest/LandingPage/${backgroundImage}.svg`)}
        alt="landing"
      />
      <img
        className="landinimg1"
        src={require(`../../assest/LandingPage/${backgroundImage1}.svg`)}
        alt="landing"
      />

      <div className={landtextGallery ? "landtextGallery" : "landtext"}>
        <h1>{title}</h1>
        <p>{description}</p>
        <LandingButton onClick={rfidhandle}>To Know More</LandingButton>
      </div>
      {modalshow && (
        <Modal modalshow={modalshow} setModalShow={setModalShow}>
          <Contact />
        </Modal>
      )}
    </div>
  );
};

export default LandingPage;
