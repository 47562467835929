import React from "react";
import LandingPage from "../../component/landingPage/LandingPage";
import AboutUs from "./AboutUs";
import CircleAnimation from "./CircleAnimation";
import MobileApp from "./MobileApp";
import RevenueDetails from "./RevenueDetails";
import OurService from "./OurService";
import Testimonial from "../../component/testimonial/Testimonial";
import CarCleanImportant from "./CarCleanImportant";

const Home = () => {
  const backgroundImage = "landingHome";
  const backgroundImage1 = "landingpagemobile";

  const pageLanding = [
    {
      title: "Clean Car is our Priority",
      description:
        "Experience the ultimate in automotive rejuvenation with our professional car Clean services. Your journey starts here, where every detail matters, and your vehicle's shine is our top priority.",
      backgroundImage: backgroundImage,
      backgroundImage1: backgroundImage1,
    },
  ];

  return (
    <>
      <LandingPage {...pageLanding[0]} />
      <AboutUs />
      <CircleAnimation />
      <OurService />
      <MobileApp />
      <RevenueDetails />
      <CarCleanImportant />
      <Testimonial />
    </>
  );
};

export default Home;
