import React from "react";
import AboutImg from "../../assest/Home/AboutUsImg.svg";
import "./AboutUs.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import LandingButton from "../../component/button/LandingButton";
import { useNavigate } from "react-router-dom";

const AboutUs = () => {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate("/About_Us");
  };

  return (
    <div className="aboutMain">
      <div className="imgsec11">
        <img src={AboutImg} alt="AboutImg" />
      </div>
      <div className="aboutTextSec">
        <div className="abouttext12">
          <FontAwesomeIcon icon={faCircle} className="iconcircleBlack" />
          <h5>About Us</h5>
        </div>
        <h1>We are professionals in car cleaning</h1>
        <p>
          Fragrant Cars and Solutions Pvt. Ltd., is an innovative yet
          need-of-the-hour thought for all individuals who own their dream car.
          Most of the time the car is bought with full of dreams and aspirations
          but most of them are unable to carry the cleanliness and image of
          their prized possession! Over the more handling the cleaning labor is
          a greater challenge nowadays given the level of inconsistency and at
          the same time customers losing their money knowing that their car is
          not clean enough!
        </p>
        {/* <p>
          Clean Car gives you a kind of freshness for the day ! Just we want you
          to travel as fresh as you can.
        </p> */}
        <LandingButton onClick={handleButtonClick} btnitem>
          To Know More
        </LandingButton>
      </div>
    </div>
  );
};

export default AboutUs;
