import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./Navbar.css";
import logoimg from "../../assest/NavbarLogo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import Modal from "../modal/Modal";
import Contact from "../../pages/contact_Us/Contact";

const Navbar = ({ home, about, contact }) => {
  const [navToggle1, setNavToggle1] = useState(false);
  const [modalshow, setModalShow] = useState(false);
  const rfidhandle = () => {
    setModalShow(true);
  };

  return (
    <section className="navbar">
      <nav>
        <NavLink to="/" exact activeClassName="active">
          <img src={logoimg} alt="logo" className="logo" />
        </NavLink>
        <div className={navToggle1 ? "menu2" : ""}>
          <div className="mobile">
            <ul>
              <li>
                <div>
                  <NavLink to="/" target="_parent" activeClassName="active">
                    Home
                  </NavLink>
                </div>
              </li>
              <li>
                <div>
                  <NavLink
                    to="/services"
                    target="_parent"
                    activeClassName="active"
                  >
                    Services
                  </NavLink>
                </div>
              </li>
              <li>
                <div>
                  <NavLink
                    to="/About_Us"
                    target="_parent"
                    activeClassName="active"
                  >
                    About Us
                  </NavLink>
                </div>
              </li>
              <li>
                <div>
                  <NavLink
                    to="/gallery"
                    target="_parent"
                    activeClassName="active"
                  >
                    Gallery
                  </NavLink>
                </div>
              </li>
              <li>
                <button onClick={rfidhandle} className="contact">
                  Contact us
                </button>
              </li>
            </ul>
          </div>
        </div>
        <FontAwesomeIcon
          icon={navToggle1 ? faXmark : faBars}
          className="bars"
          onClick={() => setNavToggle1(!navToggle1)}
        />
      </nav>
      {modalshow && (
        <Modal modalshow={modalshow} setModalShow={setModalShow}>
          <Contact />
        </Modal>
      )}
    </section>
  );
};

export default Navbar;
