import React from 'react'
import Carosal from './Carosal'
import TestimonialText from './TestimonialText'

const Testimonial = () => {
  return (
      <div className='testimonialmain'>
          <TestimonialText />
          <Carosal />
    </div>
  )
}

export default Testimonial