import React, { useState } from "react";
import DirtImg from "../../assest/Home/Dirt.svg";
import HealthImg from "../../assest/Home/Health.svg";
import CarpetsImg from "../../assest/Home/Carpets.svg";
import ResaleImg from "../../assest/Home/Resale.svg";
import EnergyImg from "../../assest/Home/Energy.svg";
import LifeSpanImg from "../../assest/Home/LifeSpan.svg";
import "./CarCleanImportant.css";

const CarCleanImportant = () => {
  const [showFullContent, setShowFullContent] = useState({});

  const toggleShowFullContent = (id) => {
    setShowFullContent((prevState) => {
      const updatedState = {};
      Object.keys(prevState).forEach((key) => {
        updatedState[key] = key === id ? !prevState[key] : false;
      });
      updatedState[id] = !prevState[id];
      return updatedState;
    });
  };

  const carCleanList = [
    {
      id: 1,
      title4: "Gets rid of dirt",
      pic5: DirtImg,
      description2:
        "If you use your car for transportation purposes, both the outside and the inside are likely to become dirty over time. It is necessary to clean your automobile consistently to get rid of the filth that builds up, such as rain markings, as well as the dust that gathers on the interior and outside of the vehicle.",
      description3:
        "If you don’t keep hold of the dirt in your car, it could end up damaging the aesthetic appearance of your vehicle, such as the paint, and it could even damage sections of the inside, such as the air cooling system. If this happens, you need to take care of the dirt immediately. It is also essential to keep in mind that the likelihood of the paintwork on your vehicle being damaged beyond repair!",
    },
    {
      id: 2,
      title4: "Interior and Exterior Health",
      pic5: HealthImg,
      description2:
        "Your vehicle’s interior is susceptible to fast becoming contaminated with allergens from pets, dust, filth, food particles, soda splashes, coffee stains, and other potentially hazardous substances. Your vehicle’s interior may eventually end up looking drab over time. In addition to giving the area a terrible appearance, the accumulation of filth and bacteria can give off an offensive odor and contribute to the spread of disease.",
      description3:
        "A method called detailing can remove any dirt and bacteria accumulated on your vehicle’s leather surfaces. This includes the entertainment console, the audio system, the dashboard, and any seats or other surfaces that are made of leather. In order to ensure that the interior surfaces of your vehicle are fully cleaned, the use of chemicals that are created specifically for cleaning leather components and automobile interiors is required. Doing this will help prevent bacteria from producing health problems for your family members.",
    },
    {
      id: 3,
      title4: "Maintain Your Carpets",
      pic5: CarpetsImg,
      description2:
        " Carpets are an essential component of automobiles. Even if we don’t give it much thought, carpets contribute to the overall experience of driving being one that is both more comfortable and more luxurious. However, because here is where your shoes are kept, they have the potential to become one of those spots that gather a great deal of filth. Who knows what kinds of bacteria your shoes might have picked up along the way? If the carpet fibers are not properly maintained for an extended period, they are more likely to become damaged. Therefore, maintaining the cleanliness of the carpets in your vehicle is essential to keeping them in good condition.",
    },
    {
      id: 4,
      title4: "Resale Value",
      pic5: ResaleImg,
      description2:
        "If you use your car for transportation purposes, both the outside and the inside are likely to become dirty over time. It is necessary to clean your automobile consistently to get rid of the filth that builds up, such as rain markings, as well as the dust that gathers on the interior and outside of the vehicle.",
      description3:
        "If you don’t keep hold of the dirt in your car, it could end up damaging the aesthetic appearance of your vehicle, such as the paint, and it could even damage sections of the inside, such as the air cooling system. If this happens, you need to take care of the dirt immediately. It is also essential to keep in mind that the likelihood of the paintwork on your vehicle being damaged beyond repair!. ",
    },
    {
      id: 5,
      title4: "Increased Productivity in the Use of Energy",
      pic5: EnergyImg,
      description2:
        "It has been demonstrated in a number of studies that a vehicle with lower levels of pollution also uses less gasoline overall. This is because the car’s surfaces are designed to be as smooth and aerodynamic as possible, enabling it to move through the airspace without exerting any effort. Your vehicle’s aerodynamic capability can be negatively impacted by even a little layer of dirt, making it more difficult to penetrate wind and increasing the amount of drag. The energy efficiency of a building can increase by as much as 10 for every clean surface. In addition to this, performing routine maintenance on your vehicle can also assist reduce the carbon footprint left by your vehicle",
    },
    {
      id: 6,
      title4: "Extends the vehicle’s Lifespan",
      pic5: LifeSpanImg,
      description2:
        "Cars that are routinely washed retain their like-new appearance for a significantly longer period of time. Additionally, automobiles that are cleaned on a regular basis always have a clean appearance and a clean scent to them. To maintain good health, the body really must have water; there is no other option. According to a number of studies, vehicles that are washed regularly have a greater chance of retaining their pristine appearance and preventing damage to the components found within.",
      description3:
        "Maintaining a clean vehicle is important for a variety of reasons, including improving your gas mileage and having more enjoyable road trips with your friends. In addition to removing unnecessary items and vacuuming the interior of your vehicle, you should make it a habit to wash your automobile on a regular basis. After all, you’ll want the outside of your car to seem equally as wonderful as the inside’s appearance!",
      description4:
        "Keep in mind that the closest car wash should be frequented to maintain your vehicle’s cleanliness. Your vehicle will be protected if you stay on top of its basic maintenance and get it detailed regularly, and you’ll have the satisfaction of knowing that you’re driving the best-looking vehicle that’s possible. Keeping your vehicle clean is crucial for a variety of reasons, and FRAGRANT CARS is here to assist you in doing this task.",
    },
  ];

  return (
    <div className="carclean">
      <div className="subcarclean">
        <div className="carCleanText">
          <h1>Why Car Cleaning is important</h1>
          <p>
            As the saying goes “Cleanliness is next to Godliness” – We as a
            startup found the very need that finding a disciplined labor for car
            cleaning is quite challenging. Seldom it happens that we are able to
            clean our car as planned. Experience our best in class professional
            cleaning services on a routine basis which will keep your car clean,
            in addition to these cleaning services we offer car services at free
            of cost which will ensure you are experiencing a technically fit car
            for your driving passion.
          </p>
        </div>
        <div className="carCleanCard">
          {carCleanList?.map((data8) => (
            <div className="cardClean" key={data8.id}>
              <div className="subcard1">
                <img src={data8.pic5} alt={data8.title4} />
                <h2>{data8.title4}</h2>
              </div>
              <p>
                {showFullContent[data8.id]
                  ? data8.description2
                  : data8.description2.slice(0, 245)}
              </p>
              {!showFullContent[data8.id] && (
                <span onClick={() => toggleShowFullContent(data8.id)}>
                  Show More
                </span>
              )}
              {showFullContent[data8.id] && (
                <>
                  <p>{data8.description3}</p>
                  <span onClick={() => toggleShowFullContent(data8.id)}>
                    Show Less
                  </span>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CarCleanImportant;
