import React from "react";
import LandingPage from "../../component/landingPage/LandingPage";
import ServiceProvider from "./ServiceProvider";

const AboutUsMain = () => {
  const backgroundImage = "landingAboutUs";
  const backgroundImage1 = "LandingAboutMobile";

  const pageLanding = [
    {
      id: 1,
      title: "We are professionals in Car cleaning",
      description:
        "Fragrant Cars and Solutions Pvt. Ltd., is an innovative yet need-of-the-hour thought for all individuals who own their dream car. ",
      backgroundImage: backgroundImage,
      backgroundImage1: backgroundImage1,
    },
  ];
  return (
    <div>
      <LandingPage {...pageLanding[0]} />
      <ServiceProvider />
    </div>
  );
};

export default AboutUsMain;
