import React, { useState } from "react";
import MothlyService from "./MothlyService";
import YearlyService from "./YearlyService";
import "./SubscriptionService.css";

const SubscriptionService = () => {
  const [serviceType, setServiceType] = useState("Monthly");
  const [loadingData, setLoadingData] = useState(false);

  const handleService = (service) => {
    if (service !== serviceType) {
      setServiceType(service);
    }
  };

  return (
    <div className="servicetypemain">
      <div className="subServicetype">
        <div className="servicetypetext">
          <h1>Our subscription Plans</h1>
          <p>
            At Fragrant Cars and Solutions Pvt. Ltd.,, we believe in providing
            flexible and convenient solutions to meet the diverse needs of our
            valued customers. Our subscription plans are crafted to offer a
            range of benefits, ensuring that you get the most out of your
            experience with us.
          </p>
        </div>
        <div className="servicetype">
          <button
            className={serviceType === "Monthly" ? "active" : ""}
            onClick={() => handleService("Monthly")}
          >
            Monthly
          </button>
          <button
            className={serviceType === "Yearly" ? "active" : ""}
            onClick={() => handleService("Yearly")}
          >
            Yearly
          </button>
        </div>
        {serviceType === "Monthly" ? (
          <MothlyService
            loadingData={loadingData}
            setLoadingData={setLoadingData}
          />
        ) : (
          <YearlyService
            loadingData={loadingData}
            setLoadingData={setLoadingData}
          />
        )}
      </div>
    </div>
  );
};

export default SubscriptionService;
