import React from "react";
import "./MobileApp.css";
import AppIcon from "../../assest/Home/App.svg";
import ServiceIcon from "../../assest/Home/service.svg";
import AddressIcon from "../../assest/Home/Address.svg";
import PeopleIcon from "../../assest/Home/people.svg";
import CashIcon from "../../assest/Home/Cash.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import MobileAppIcon from "../../assest/Home/MobileApp.svg";

const MobileApp = () => {
  const mobileApplist = [
    {
      id: 1,
      icon: AppIcon,
      description: "Download the App",
    },
    {
      id: 2,
      icon: ServiceIcon,
      description: "Select the Service",
    },
    {
      id: 3,
      icon: AddressIcon,
      description: "Select Location and Address",
    },
    {
      id: 4,
      icon: PeopleIcon,
      description: "Our qualified People will reach your Location",
    },
    {
      id: 5,
      icon: CashIcon,
      description: "Pay via App / Cash",
    },
  ];
  return (
    <div className="mainMobileApp">
      <div className="subMobileApp">
        <div>
          <div className="mobiletext">
            <FontAwesomeIcon icon={faCircle} className="iconcircleBlack" />
            <h5>About Mobile App</h5>
          </div>
          <h3>How to download Mobile app? </h3>
          <p>
            Welcome to Fragrant Cars and Solutions Pvt. Ltd., We're thrilled to
            have you on board. To make the most of our services, follow these
            simple steps to download our mobile application
          </p>
          <div>
            {mobileApplist.map((item1) => (
              <div className="submobileservice" key={item1?.id}>
                <img
                  className="mobileicon"
                  src={item1?.icon}
                  alt={item1?.icon}
                />
                <div className="mobilebox123">
                  <h2>{item1?.description}</h2>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="mobileimg1">
          <img className="mobileimg" src={MobileAppIcon} alt="MobileAppIcon" />
        </div>
      </div>
    </div>
  );
};

export default MobileApp;
