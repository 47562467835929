import React from "react";
import ServiceDaily from "./ServiceDaily";
import ServiceAddOn from "./ServiceAddOn";
import ServiceDoorStep from "./ServiceDoorStep";
import "./ServiceMain.css";

const ServiceMain = () => {
  return (
    <div className="servicemain1">
      <div className="mainservice1">
        <div className="servicetext1">
          <h1>We are professionals in Car cleanings</h1>
          <p>
            The delight in our services and value added benefit is that - You
            get your car services at FREE which is going to reduce your
            expenditure on your favourite & Lovable CAR of yours!
          </p>
        </div>
        <div>
          <ServiceDaily />
          <ServiceAddOn />
          <ServiceDoorStep />
        </div>
      </div>
    </div>
  );
};

export default ServiceMain;
