import React from "react";
import "./DownloadMobileApp.css";
import DownloadMobileAppimg from "../../assest/Services/mobileAppDownload.svg";
import AppStoreIos from "../../assest/Services/appStoreBlack.svg";
import AppStore from "../../assest/Services/playStoreBlack.svg";

const DownloadMobileApp = () => {
  return (
    <div className="downloadmobile">
      <div className="subdownloadmobile">
        <div className="downloadmobiletext">
          <h1>Download The Mobile App</h1>
          <p>
            Welcome to Fragrant Cars and Solutions Pvt. Ltd., We're thrilled to
            have you on board. To make the most of our services, follow these
            simple steps to download our mobile application
          </p>
          <h4>Download The App </h4>
          <h3>Fragrant Cars</h3>
          <div className="store12">
            <img src={AppStoreIos} alt="AppStoreIos" />
            <img src={AppStore} alt="AppStoreIos" />
          </div>
        </div>
        <div>
          <img
            className="downloadmobileimg"
            src={DownloadMobileAppimg}
            alt="DownloadMobileAppimg"
          />
        </div>
      </div>
    </div>
  );
};

export default DownloadMobileApp;
