import React, { useEffect, useState } from "react";
import RectangleImg from "../../assest/Services/Rectangle 84.svg";
import "./MothlyService.css";
import line7 from "../../assest/Services/Ln7.svg";
import DownloadMobileApp from "./DownloadMobileApp";
import Modal from "../modal/Modal";

const YearlyService = ({ loadingData, setLoadingData }) => {
  const [modalshow, setModalShow] = useState(false);
  const [yearlyData, setYearlyData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoadingData(true);
      try {
        const response = await fetch(
          "Android_API/api-1.2.11/plan_details.php?subs_type=Yearly",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch monthly data");
        }

        const data = await response.json();
        setYearlyData(data.plan_details);
        setLoadingData(false);
      } catch (error) {
        console.error("Error fetching monthly data:", error);
        setLoadingData(false);
      }
    };

    fetchData();
  }, []);

  if (loadingData) return <div>Loading Data</div>;

  const rfidhandle = () => {
    setModalShow(true);
  };

  return (
    <div className="monthlymain12">
      <div className="monthlymain">
        {yearlyData?.map(
          (data7) =>
            data7.car_model !== "Free" && (
              <div key={data7?.id} className="yearlybox">
                <img className="img22" src={RectangleImg} alt="RectangleImg" />
                <div className="monthlytext">
                  <h6>{data7?.car_model}</h6>
                  <h2>{data7?.subcription_type}</h2>
                  <h3>
                    Rs. <span> {data7?.yearly_price} </span>/Year Actual Payment
                    <img src={line7} alt="line7" />
                  </h3>
                  <h4>{data7?.complement_details}</h4>
                  <button onClick={rfidhandle} className="btn5">
                    Book now
                  </button>
                </div>
              </div>
            )
        )}
      </div>
      {modalshow && (
        <Modal modalshow={modalshow} setModalShow={setModalShow}>
          <DownloadMobileApp />
        </Modal>
      )}
    </div>
  );
};

export default YearlyService;
