import React, { useState } from "react";
import "./Footer.css";
import footerlogo from "../../assest/NavbarLogo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import emailjs from "emailjs-com";
import AppStoreIos from "../../assest/footer/AppStoreIOS.svg";
import AppStore from "../../assest/footer/AppStore.svg";
import phoneIcon from "../../assest/footer/phoneimg.svg";
import Contact from "../../pages/contact_Us/Contact";
import Modal from "../modal/Modal";
import DeleteAccountForm from "./DeleteAccountForm";

const Footer = () => {
  const currentyear = new Date().getFullYear();
  const [modalshow, setModalShow] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const openModal = (content) => {
    setModalContent(content);
    setModalShow(true);
  };

  const initialValues = {
    email: "",
    message: "subscribed to our newsletter and updates.",
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubscribe = (e) => {
    e.preventDefault();
    const errors = validate(formValues);
    setFormErrors(errors);
    setIsSubmit(true);

    if (Object.keys(errors).length === 0) {
      emailjs
        .send(
          "service_2jjnq15",
          "template_x8ah22t",
          formValues,
          "tE_o6vBiidb8yU2Wj"
        )
        .then((response) => {
          alert("Thanks");
          console.log("Email sent successfully!", response);
        })
        .catch((error) => {
          alert("Error sending email: " + error);
          console.error("Error sending email:", error);
        });
    } else {
      alert("Kindly enter the required fields");
      console.log("Form has errors. Submit prevented.");
    }
  };

  const validate = (values) => {
    const errors = {};
    const regex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!values.email) {
      errors.email = "Email is required!";
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid email format!";
    }
    return errors;
  };

  return (
    <>
      <footer>
        <div className="footermain">
          <div className="footersec1">
            <Link to="/" target="_parent">
              <img className="logoicon" src={footerlogo} alt="footerlogo" />
            </Link>
            <div className="socialmedia">
              <Link to="" target="_blank">
                <div className="circleiconbg">
                  <FontAwesomeIcon
                    icon={faFacebookF}
                    className="fontawesomeicon"
                  />
                </div>
              </Link>
              <Link to="" target="_blank">
                <div className="circleiconbg">
                  <FontAwesomeIcon
                    icon={faInstagram}
                    className="fontawesomeicon"
                  />
                </div>
              </Link>
              <Link to="" target="_blank">
                <div className="circleiconbg">
                  <FontAwesomeIcon
                    icon={faLinkedinIn}
                    className="fontawesomeicon"
                  />
                </div>
              </Link>
              <Link to="" target="_blank">
                <div className="circleiconbg">
                  <FontAwesomeIcon
                    icon={faXTwitter}
                    className="fontawesomeicon"
                  />
                </div>
              </Link>
            </div>
            <div className="contactpart">
              <img src={phoneIcon} alt="phoneIcon" />
              <div>
                <p>Quick Contact</p>
                <h1>
                  (+91) 7358705035 / <br /> 9840792337
                </h1>
              </div>
            </div>
          </div>

          <ul className="footersec1">
            <li>
              <Link to="/" target="_parent">
                Home
              </Link>
            </li>
            <li>
              <Link to="/services" target="_parent">
                Services
              </Link>
            </li>
            <li>
              <Link to="/About_Us" target="_parent">
                About Us
              </Link>
            </li>
            <li>
              <Link to="/gallery" target="_parent">
                Gallery
              </Link>
            </li>
            <li>
              <Link onClick={() => openModal("Contact")}>Contact Us</Link>
            </li>
          </ul>
          <ul className="footersec1">
            <li>
              <Link to="/privacyPolicy">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/termsCondition">Terms and Conditions</Link>
            </li>
            <li>
              <Link to="/refundPolicy">Refund Policy</Link>
            </li>
            <div>
              <h2>Download App</h2>
              <div className="stores1">
                <img className="store12" src={AppStoreIos} alt="AppStoreIos" />
                <img className="store12" src={AppStore} alt="AppStore" />
              </div>
            </div>
          </ul>
          <div className="footersec1">
            <label>Enter Your E-mail</label>
            <div className="emilsec">
              <input
                type="email"
                name="email"
                value={formValues.email}
                onChange={handleChange}
                id=""
              />
              {formErrors.email && <p className="error">{formErrors.email}</p>}
            </div>
            <button onClick={handleSubscribe}>Subscribe</button>

            <p className="delete">
              Delete your User Account?
              <span
                onClick={() => openModal("DeleteAccount")}
                className="deleteclc"
              >
                Click here
              </span>
            </p>
          </div>
        </div>
      </footer>
      <section className="copy">
        <p>Terms and Conditions | Privacy Policy</p>
        <h1>© Copyright {currentyear}. All rights reserved by Aptonworks</h1>
      </section>

      {modalshow && (
        <Modal modalshow={modalshow} setModalShow={setModalShow}>
          {modalContent === "Contact" && <Contact />}
          {modalContent === "DeleteAccount" && <DeleteAccountForm />}
        </Modal>
      )}
    </>
  );
};

export default Footer;
