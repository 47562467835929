import React, { useState, useEffect } from "react";
import "./MothlyService.css";
import RectangleImg from "../../assest/Services/Rectangle 84.svg";
import line7 from "../../assest/Services/Ln7.svg";
import Modal from "../modal/Modal";
import DownloadMobileApp from "./DownloadMobileApp";

const MothlyService = ({ loadingData, setLoadingData }) => {
  const [modalshow, setModalShow] = useState(false);
  const [monthlyData, setMonthlyData] = useState([]);
  console.log(monthlyData);

  useEffect(() => {
    const fetchData = async () => {
      setLoadingData(true);
      try {
        const response = await fetch(
          "Android_API/api-1.2.11/plan_details.php?subs_type=Monthly",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch monthly data");
        }

        const data = await response.json();
        setMonthlyData(data.plan_details);
        setLoadingData(false);
      } catch (error) {
        console.error("Error fetching monthly data:", error);
        setLoadingData(false);
      }
    };

    fetchData();
  }, []);

  const rfidhandle = () => {
    setModalShow(true);
  };

  if (loadingData) return <div>Loading Data...</div>;
  return (
    <div className="monthlymain12">
      <div className="monthlymain">
        {monthlyData.map(
          (data7) =>
            data7.car_model !== "Free" && (
              <div key={data7.id} className="monthlybox">
                <img className="img22" src={RectangleImg} alt="RectangleImg" />
                <div className="monthlytext">
                  <h6>{data7.car_model}</h6>
                  <h2>{data7.subcription_type}</h2>
                  <h3>
                    Rs. <span> {data7?.monthly_price} </span>/Monthly
                    <img src={line7} alt="line7" />
                  </h3>
                  <button onClick={rfidhandle} className="btn4">
                    Book now
                  </button>
                </div>
              </div>
            )
        )}
      </div>
      {modalshow && (
        <Modal modalshow={modalshow} setModalShow={setModalShow}>
          <DownloadMobileApp />
        </Modal>
      )}
    </div>
  );
};

export default MothlyService;
