import React, { useState } from "react";
import "./OurService.css";
import DailyCashImg from "../../assest/Home/DailyCash.svg";
import AddOnImg from "../../assest/Home/AddOn.svg";
import DoorStepImg from "../../assest/Home/DoorStep.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import LandingButton from "../../component/button/LandingButton";
import { useNavigate } from "react-router-dom";

const OurService = () => {
  const [crossIndex, setCrossIndex] = useState(0);
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate("/services");
  };

  const handleMouseEnter = (index) => {
    setCrossIndex(index);
  };
  const serviceBoxlist = [
    {
      id: 1,
      pic3: DailyCashImg,
      title1: "Daily Car Cleaning",
      description:
        "to keep your vehicle looking its best every day, ensuring a pristine appearance and preserving its value.",
    },
    {
      id: 2,
      pic3: AddOnImg,
      title1: "Add on Service",
      description:
        "Our range of add-on services is designed to elevate your satisfaction and meet the unique needs of your vehicle.",
    },
    {
      id: 3,
      pic3: DoorStepImg,
      title1: "Doorstep Car Machine Polish",
      description:
        "A convenient and effective way to bring back the showroom shine without leaving the comfort of your home.",
    },
  ];

  return (
    <div className="ourServicemain">
      <div className="subOurService">
        <div>
          <div className="servicetexts">
            <FontAwesomeIcon icon={faCircle} className="iconcircleWhite" />
            <h5>Our Services</h5>
          </div>
        </div>
        <h1>Giving the best service for our clients</h1>
        <h2>
          At Fragrant Cars and Solutions Pvt. Ltd., we understand that
          exceptional service is the cornerstone of lasting partnerships. Our
          unwavering commitment to making your experience unparalleled is
          embedded in every aspect of our operations.
        </h2>
        <div className="servicebox">
          {serviceBoxlist?.map((data4, index) => (
            <div
              className={`ourServicebox ${crossIndex === index ? "cross" : ""}`}
              key={data4?.id}
              onMouseEnter={() => handleMouseEnter(index)}
            >
              <img src={data4?.pic3} alt={data4?.title1} />
              <div className="servicetext2">
                <h3>{data4?.title1}</h3>
                <p>{data4?.description}</p>
                <LandingButton btn3Enabled onClick={handleButtonClick}>
                  To Know More
                </LandingButton>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurService;
